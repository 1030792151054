@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Montserrat Alternates', sans-serif;
  font-family: 'Poppins', sans-serif;
  
}



.herox{
    position: relative;
    background-image: url("https://eu-images.contentstack.com/v3/assets/blt66983808af36a8ef/blt180664b686e9cbcb/619bf0ddadbd9251f8513962/WomanSecurityJob_Maskot_Alamy.jpg");
    background-size: cover;
    background-position: center;
    height: fit-content; 
  }
  
  .herox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .border__nav{
    border: solid 2px white;
    background-color: white;
    /* opacity: 50%; */
    
  }

  .border__down{
    border-bottom: solid #0779e4 2px;
  }

  .get__one{
    background-color: #0779e4;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
  }

.get__two{
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color:#0779e4;
    border-radius: 5px;
}

.span_x{
    border-bottom: #0779e4 solid 2px;
}



.numbers{
    position: relative;
    background-image: url("https://media.istockphoto.com/id/1194430803/photo/smart-female-it-programer-working-on-desktop-computer-in-data-center-system-control-room-team.jpg?s=612x612&w=0&k=20&c=ZqM_E-kjQaAtAV04I1LjW2jZo-q2YBVeT_NKora-1sM=");
    background-size: cover;
    background-position: center;
    height: fit-content; 
  }
  
  .numbers::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .serv__box{
    box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
    border-bottom: #0779e4 solid 2px;
  }

  .learn__x{
    border: solid #0779e4 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    transition: 350ms ease-in-out;
  }

.learn__x:hover{
    background-color: #0779e4;
    color: white;
}

.wavex{
    /* position: relative;
    background-image: url("/public/wavex.png");
    background-size: cover;
    background-position: center;
    height: fit-content; */
    
}

.review__bord{
    border-bottom: solid 3px #0779e4;
}




.hero {
    position: relative;
    background-image: url('https://api.hub.jhu.edu/factory/sites/default/files/styles/landscape/public/customer-service-hub.jpg');
    background-size: cover;
    background-position: center;
    height: 300px; /* You can adjust the height as needed */
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1; /* Make sure the text and button are above the pseudo-element */
  }
  
  .hero-content h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }


  .contact-btn{
    background-color: #0779e4;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  
.Bitcoin{
  position: relative;
  background-image: url("https://miro.medium.com/v2/resize:fit:1400/1*Wq6rqVkGipvcOVqmaSls0g.jpeg");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Bitcoin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}

.right__border{
  border-left: solid 2px #0779e4;
}

.Romance{
  position: relative;
  background-image: url("https://img.freepik.com/premium-photo/unhappy-young-woman-crying-wiping-tears-with-handkerchief-suffering-from-grief-divorce-with-boyfriend-broken-heart-sitting-alone-bed-home_482921-10861.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.Romance::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Blackmail{
  position: relative;
  /* background-image: url("https://media.istockphoto.com/id/577639382/photo/bad-news-comes-calling.jpg?s=612x612&w=0&k=20&c=YUE9v12xbgAKJG29XrJ74fNtNq7qAK9zBOijEX_RDVg="); */
  background-image: url(https://img.freepik.com/premium-photo/stressed-unhappy-scared-crying-woman-victim-fear-with-closed-mouth-dark-black_122732-2521.jpg);
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.Blackmail::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Social{
  position: relative;
  /* background-image: url("https://media.istockphoto.com/id/577639382/photo/bad-news-comes-calling.jpg?s=612x612&w=0&k=20&c=YUE9v12xbgAKJG29XrJ74fNtNq7qAK9zBOijEX_RDVg="); */
  background-image: url("https://img.freepik.com/free-photo/happy-smiling-millennial-girl-with-red-hair-holds-modern-cellular-enjoys-texting-social-media-uses-mobile-network-services-wears-blue-jumper-hat_273609-46497.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.Social::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}


.Phone{
  position: relative;
  /* background-image: url("https://media.istockphoto.com/id/577639382/photo/bad-news-comes-calling.jpg?s=612x612&w=0&k=20&c=YUE9v12xbgAKJG29XrJ74fNtNq7qAK9zBOijEX_RDVg="); */
  background-image: url("https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?cs=srgb&dl=pexels-pixabay-39284.jpg&fm=jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.Phone::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Account{
  position: relative;
  /* background-image: url("https://media.istockphoto.com/id/577639382/photo/bad-news-comes-calling.jpg?s=612x612&w=0&k=20&c=YUE9v12xbgAKJG29XrJ74fNtNq7qAK9zBOijEX_RDVg="); */
  background-image: url("https://hackernoon.com/images/a-person-typing-on-a-keyboard-nj9mmtfprxp7n7n4q8j1qpwm.png");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.Account::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* CSS for the fade-in and fade-out animation */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.Contact::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.Contact{
  position: relative;
  /* background-image: url("https://media.istockphoto.com/id/577639382/photo/bad-news-comes-calling.jpg?s=612x612&w=0&k=20&c=YUE9v12xbgAKJG29XrJ74fNtNq7qAK9zBOijEX_RDVg="); */
  background-image: url("https://api.hub.jhu.edu/factory/sites/default/files/styles/landscape/public/customer-service-hub.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.About{
  position: relative;
  /* background-image: url("https://media.istockphoto.com/id/577639382/photo/bad-news-comes-calling.jpg?s=612x612&w=0&k=20&c=YUE9v12xbgAKJG29XrJ74fNtNq7qAK9zBOijEX_RDVg="); */
  background-image: url("https://ia.tmgrup.com.tr/be4d80/806/378/0/131/1429/803?u=https://i.tmgrup.com.tr/anews/v1/2023/10/06/new-investigative-report-claims-athens-was-center-of-israeli-spyware-company-1696609191314.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.About::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}


.custom-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
}

.custom-progress {
  position: relative;
  width: 100px;
  height: 100px;
}

.custom-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  width: 100%;
  color: #0779e4;
  animation: pulse-bounce 1.5s ease-in-out infinite;
  transition: transform 0.2s ease-in-out;
}

@keyframes pulse-bounce {
  0%, 100% {
    opacity: 1;
    transform: translate(-50%, -50%); /* Start and end position for the bounce */
  }
  50% {
    opacity: 0.5;
    transform: translate(-50%, -65%); /* Bounce position */
  }
}

/* .custom-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-top-color: #0779e4;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */



.Shadow__x{
  box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
    border-bottom: #0779e4 solid 2px;
}

.shadow__box{
  border-bottom: 1px solid #007FFF;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(6, 141, 232, 0.5);    ;
}


.border__b{
  border: #1b9bf0 solid 1px;
  background: rgba(0, 127, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
}


.Button__{
  border: #007FFF solid 1px;
  background: rgba(0, 127, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
  padding: 9px;
  margin-top: 10px;
  color: white;
  transition: 300ms ease-in-out;
}

.serv__boreder{
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
  border-radius: 10px;
}

.avater__1{
  border: solid 2px rgba(0, 127, 255, 1);
}

.serv__box{
  box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
  border-bottom: #0779e4 solid 2px;
}


.btn__touch1{
  transition: ease-in-out 300ms;
}

.btn__touch1:hover{
  background-color: #007FFF;
  border: solid 0.6px whitesmoke;
}
